import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState, } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import MuiInputLabel from '@mui/material/InputLabel';
import isPropValid from '@emotion/is-prop-valid';
import { animated, useSpring } from '@react-spring/web';
import { useTheme } from '@mui/material';
import ErrorMessage from '@shared/ui/errors/ErrorMessage';
import InputLabel from '@shared/ui/inputs/InputLabel';
import { ReactComponent as MinusIcon } from '@icons/wolfkit-light/minus-light.svg';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { roundToMultipleOf } from '@utils/math';
import { debounceWrapper } from '@utils/async-utils';
import { IsDefined } from '@utils/js-ts';
import { INPUT_SIZES } from './config';
const BOX_SHADOW_FOCUSED = '0px 0px 0px 3px rgba(136, 144, 150, 0.10)';
const BOX_SHADOW_DEFAULT = '0px 0px 0px 3px transparent';
const QuantityInputContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })((props) => (Object.assign({ display: 'flex', flexDirection: 'column', width: props.fullWidth ? '100%' : 'auto' }, props.disabled && ({
    opacity: 0.5,
    userSelect: 'none',
    '& *': {
        cursor: 'default!important',
        pointerEvents: 'none',
    },
}))));
const InputContainer = styled.div(() => ({
    display: 'flex',
    height: '40px',
    flexDirection: 'row',
}));
const IconAnimated = animated(styled(Icon)());
const ControlButton = animated(styled.button((props) => (Object.assign({ display: 'flex', flexShrink: 0, boxSizing: 'border-box', cursor: 'pointer', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', userSelect: 'none', color: props.theme.palette.primary.main, borderRadius: props.right ? '0px 3px 3px 0px' : '3px 0px 0px 3px', border: `1px solid ${props.theme.customColors.input.border}`, backgroundColor: props.theme.customColors.menu.item.select, outline: 'unset' }, props.right ? ({
    borderLeft: 'none',
}) : ({
    borderRight: 'none',
})))));
const CircularProgressStyled = styled(CircularProgress)(props => ({
    color: props.theme.palette.text.secondary,
    marginLeft: props.theme.spacing_sizes.xs,
}));
const GetInputSuffixContent = (variant, position) => {
    let content = 'none';
    if (variant === 'percent') {
        content = '"%"';
    }
    else if (variant === 'position') {
        content = `/${position || 0}`;
    }
    return content;
};
const GetInputSuffixWidth = (variant, position) => {
    let width = 1;
    if (variant === 'position') {
        width = String(position || 0).length + 1;
    }
    return width;
};
const InputStyled = styled(Input, {
    shouldForwardProp: propName => (propName !== 'size' &&
        propName !== 'positionsCount' &&
        propName !== 'isInFocus' &&
        propName !== 'isError' &&
        propName !== 'fullWidth' &&
        propName !== 'isLoading'),
})((props) => (Object.assign(Object.assign(Object.assign(Object.assign({ border: `1px solid ${props.theme.customColors.input.border}`, backgroundColor: props.theme.customColors.surface.surface, padding: INPUT_SIZES[props.size].inputPadding, justifyContent: 'center', width: props.fullWidth ? '100%' : 'auto', '& input.Mui-disabled': {
        WebkitTextFillColor: 'currentColor',
    }, '& .MuiInputAdornment-positionStart': {
        marginRight: '0.25em',
    } }, props.isInFocus && ({
    border: `1px solid ${props.theme.customColors.input.borderActive}`,
})), props.isError && ({
    border: `1px solid ${props.theme.palette.error.main}`,
})), { '& input': {
        color: props.theme.palette.text.primary,
        fontSize: INPUT_SIZES[props.size].valueSize,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: INPUT_SIZES[props.size].lineHeight,
        height: '100%',
        padding: 0,
    }, '$:after': {
        display: 'inline-flex',
        alignItems: 'center',
        width: '1ch',
        height: '100%',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textAlign: 'center',
    } }), !props.isLoading &&
    (props.variant === 'percent' || props.variant === 'position') &&
    ({
        '&:after': {
            content: GetInputSuffixContent(props.variant, props.positionsCount),
            display: 'inline-flex',
            alignItems: 'center',
            height: '100%',
            width: `${GetInputSuffixWidth(props.variant, props.positionsCount)}ch`,
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: props.variant === 'percent' ? 600 : 400,
            lineHeight: 'normal',
            textAlign: 'center',
            color: props.variant === 'percent' ? props.theme.palette.text.primary : props.theme.palette.text.secondary,
        },
    }))));
const QuantityInputLabel = styled(MuiInputLabel, { shouldForwardProp: propName => propName !== 'size' })((props) => {
    var _a, _b, _c, _d, _e;
    return ({
        position: 'relative',
        top: 0,
        left: 0,
        transform: 'none',
        marginBottom: (_a = INPUT_SIZES[props.size]) === null || _a === void 0 ? void 0 : _a.labelMarginBottom,
        fontWeight: 500,
        fontSize: (_b = INPUT_SIZES[props.size]) === null || _b === void 0 ? void 0 : _b.labelSize,
        lineHeight: (_c = INPUT_SIZES[props.size]) === null || _c === void 0 ? void 0 : _c.lineHeight,
        color: props.theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'space-between',
        '&label.MuiFormLabel-root.Mui-disabled': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            marginBottom: (_d = INPUT_SIZES[props.size]) === null || _d === void 0 ? void 0 : _d.labelMarginBottom,
            fontWeight: 500,
            fontSize: (_e = INPUT_SIZES[props.size]) === null || _e === void 0 ? void 0 : _e.labelSize,
            color: props.theme.palette.text.primary,
        },
    });
});
const Adornment = styled(Typography)((props) => ({
    userSelect: 'none',
    color: props.theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
}));
const formatNumberToFixed2 = (value) => (Math.round(value * 100) / 100).toFixed(2);
const formatNumber = (value, variant) => {
    if (variant === 'currency') {
        return formatNumberToFixed2(value);
    }
    return String(value);
};
const ValidateInputDebounceDelay = 500;
const QuantityInput = ({ className = undefined, variant = 'currency', value, positionsCount = undefined, incDecStep, min = undefined, max = undefined, roundToMultiplesOfStep = false, errorMessage = undefined, fullWidth = false, isLoading = false, disabled = false, label = undefined, size = 'medium', onChange, }) => {
    const theme = useTheme();
    const [inputValue, setInputValue] = useState(formatNumber(value, variant));
    const [inputInFocus, setInputInFocus] = useState(false);
    const [{ incrIconColor, decrIconColor, decrBoxShadow, incrBoxShadow, }, api] = useSpring(() => ({
        incrIconColor: theme.palette.primary.main,
        decrIconColor: theme.palette.primary.main,
        decrBoxShadow: BOX_SHADOW_DEFAULT,
        incrBoxShadow: BOX_SHADOW_DEFAULT,
        config: {
            mass: 1,
            tension: 100,
            friction: 15,
        },
    }), []);
    useEffect(() => {
        setInputValue(formatNumber(value, variant));
    }, [value, variant]);
    const changeValue = useCallback((newValue) => {
        onChange(newValue);
        setInputValue(formatNumber(newValue, variant));
    }, [variant, onChange]);
    const startAdornment = useMemo(() => {
        if (variant === 'percent') {
            return null;
        }
        let adornment = null;
        if (variant === 'currency') {
            adornment = '$';
        }
        else if (variant === 'position') {
            adornment = '#';
        }
        return (_jsx(InputAdornment, { position: 'start', children: _jsx(Adornment, { children: adornment }) }));
    }, [variant]);
    const endAdornment = useMemo(() => {
        if (!isLoading) {
            return null;
        }
        return (_jsx(InputAdornment, { position: 'start', children: _jsx(CircularProgressStyled, { size: 20 }) }));
    }, [isLoading]);
    const increment = useCallback(() => {
        let newValue = value + incDecStep;
        if (IsDefined(max) && newValue > max) {
            newValue = max;
        }
        changeValue(newValue);
    }, [value, incDecStep, max, changeValue]);
    const decrement = useCallback(() => {
        let newValue = value - incDecStep;
        if (IsDefined(min) && newValue < min) {
            newValue = min;
        }
        changeValue(newValue);
    }, [value, incDecStep, min, changeValue]);
    const validateInput = useCallback((v) => {
        let newValue = Number(v);
        if (newValue) {
            if (roundToMultiplesOfStep) {
                newValue = roundToMultipleOf(newValue, incDecStep);
            }
            if (IsDefined(min) && newValue < min) {
                newValue = min;
            }
            else if (IsDefined(max) && newValue > max) {
                newValue = max;
            }
            // TODO: currently if min or max value is not multiple of step value,
            // we will get incorrect values after setting min/max values in current input value.
            // Should roundToMultipleOf, but stay in boundaries of min/max.
        }
        else {
            newValue = min || 1;
        }
        changeValue(newValue);
    }, [min, max, roundToMultiplesOfStep, incDecStep, changeValue]);
    const validateInputDebounce = useMemo(() => debounceWrapper(validateInput, ValidateInputDebounceDelay), [validateInput]);
    const onInputChange = (event) => {
        let regExp = /^\d+$/;
        const newValue = event.target.value;
        if (variant === 'currency') {
            regExp = /^[0-9]*\.?[0-9]*$/;
        }
        if (!newValue || regExp.test(newValue)) {
            setInputValue(newValue);
            validateInputDebounce(newValue);
        }
    };
    const onInputFocus = () => {
        api.start({
            to: {
                decrBoxShadow: BOX_SHADOW_DEFAULT,
                incrBoxShadow: BOX_SHADOW_DEFAULT
            },
        });
        setInputInFocus(true);
    };
    const onInputBlur = () => {
        setInputInFocus(false);
    };
    const handleContainerBlur = () => {
        api.start({
            to: {
                decrBoxShadow: BOX_SHADOW_DEFAULT,
                incrBoxShadow: BOX_SHADOW_DEFAULT
            },
        });
    };
    const handleBtnEnter = (buttonType) => () => {
        if (buttonType === 'decrement') {
            api.start({
                from: { decrIconColor: theme.palette.primary.main, },
                to: { decrIconColor: theme.palette.text.primary, },
            });
            return;
        }
        api.start({
            from: { incrIconColor: theme.palette.primary.main, },
            to: { incrIconColor: theme.palette.text.primary, },
        });
    };
    const handleBtnLeave = (buttonType) => () => {
        if (buttonType === 'decrement') {
            api.start({
                from: { decrIconColor: theme.palette.text.primary, },
                to: { decrIconColor: theme.palette.primary.main, },
            });
            return;
        }
        api.start({
            from: { incrIconColor: theme.palette.text.primary, },
            to: { incrIconColor: theme.palette.primary.main, },
        });
    };
    const handleBtnFocus = (buttonType) => () => {
        if (buttonType === 'decrement') {
            api.start({
                from: { decrBoxShadow: BOX_SHADOW_DEFAULT, },
                to: { decrBoxShadow: BOX_SHADOW_FOCUSED, },
            });
            return;
        }
        api.start({
            from: { incrBoxShadow: BOX_SHADOW_DEFAULT, },
            to: { incrBoxShadow: BOX_SHADOW_FOCUSED },
        });
    };
    const handleControlDown = (buttonType) => {
        if (buttonType === 'decrement') {
            api.start({
                from: { decrBoxShadow: BOX_SHADOW_DEFAULT, },
                to: { decrBoxShadow: BOX_SHADOW_FOCUSED, },
            });
            return;
        }
        api.start({
            from: { incrBoxShadow: BOX_SHADOW_DEFAULT, },
            to: { incrBoxShadow: BOX_SHADOW_FOCUSED },
        });
    };
    const handleControlUp = (buttonType) => {
        if (buttonType === 'decrement') {
            api.start({
                from: { decrBoxShadow: BOX_SHADOW_FOCUSED, },
                to: { decrBoxShadow: BOX_SHADOW_DEFAULT, },
            });
            return;
        }
        api.start({
            from: { incrBoxShadow: BOX_SHADOW_FOCUSED, },
            to: { incrBoxShadow: BOX_SHADOW_DEFAULT },
        });
    };
    const handleBtnUp = (buttonType) => () => {
        handleControlUp(buttonType);
    };
    const handleBtnDown = (buttonType) => () => {
        handleControlDown(buttonType);
    };
    const handleKeyUp = (buttonType) => (ev) => {
        if (ev.key === 'Enter' || ev.key === ' ') {
            handleControlUp(buttonType);
        }
    };
    const handleKeyDown = (buttonType) => (ev) => {
        if (ev.key === 'Enter' || ev.key === ' ') {
            handleControlDown(buttonType);
        }
    };
    return (_jsxs(QuantityInputContainer, { className: className, disabled: disabled, fullWidth: fullWidth, onBlur: handleContainerBlur, children: [label && (_jsx(QuantityInputLabel, { disabled: disabled, size: size, children: _jsx(InputLabel, { label: label }) })), _jsxs(InputContainer, { children: [_jsx(ControlButton, { "aria-label": 'Decrease quantity', "aria-controls": 'quantity-input', type: 'button', onClick: decrement, onFocus: handleBtnFocus('decrement'), onMouseEnter: handleBtnEnter('decrement'), onMouseLeave: handleBtnLeave('decrement'), onMouseUp: handleBtnUp('decrement'), onMouseDown: handleBtnDown('decrement'), onKeyUp: handleKeyUp('decrement'), onKeyDown: handleKeyDown('decrement'), style: {
                            boxShadow: decrBoxShadow.to(boxShadow => `${boxShadow}`)
                        }, children: _jsx(IconAnimated, { size: 14, IconComponent: MinusIcon, style: {
                                color: decrIconColor.to(decrColor => `${decrColor}`),
                            } }) }), _jsx(InputStyled, { "aria-controls": 'quantity-input', variant: variant, isInFocus: inputInFocus, isError: IsDefined(errorMessage), isLoading: isLoading, positionsCount: positionsCount, value: inputValue, size: size, disableUnderline: true, startAdornment: startAdornment, endAdornment: endAdornment, onChange: onInputChange, onFocus: onInputFocus, onBlur: onInputBlur, fullWidth: fullWidth, disabled: disabled, sx: {
                            input: {
                                maxWidth: `${inputValue.length}ch`,
                            },
                        } }), _jsx(ControlButton, { "aria-controls": 'quantity-input', "aria-label": 'Increase quantity', type: 'button', right: true, onClick: increment, onMouseEnter: handleBtnEnter('increment'), onMouseLeave: handleBtnLeave('increment'), onMouseUp: handleBtnUp('increment'), onMouseDown: handleBtnDown('increment'), onKeyDown: handleKeyDown('increment'), onKeyUp: handleKeyUp('increment'), onFocus: handleBtnFocus('increment'), style: {
                            boxShadow: incrBoxShadow.to(boxShadow => boxShadow)
                        }, children: _jsx(IconAnimated, { size: 14, IconComponent: PlusIcon, style: {
                                color: incrIconColor.to(incrColor => `${incrColor}`),
                            } }) })] }), IsDefined(errorMessage) &&
                errorMessage.length > 0 && (_jsx(ErrorMessage, { errorMessage: errorMessage }))] }));
};
export default QuantityInput;
